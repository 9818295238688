require('./bootstrap');
require('bootstrap-select');
require('lightslider');
require('slick-carousel');
require('flexslider');

function changeLang(selectedLang) {
    $.each(languages, function (key, value) {
        if (selectedLang == value.value) {
            let selectedLangKey = value.key;
            let protocol = window.location.protocol;
            let hostname = window.location.hostname;
            let path = window.location.pathname;

            urlArr = path.split('/');
            let newPath = ""
            $.each(urlArr, function (i, val) {
                if (i != 0 && i != 1)
                    newPath += '/' + val;

            });
            let newUrl = protocol + "//" + hostname + "/" + selectedLangKey + newPath;

            document.location.href = newUrl;
        }

    });
}

$(window).on('load', function () {
    $('.flexslider').flexslider({
        animation: "slide",
        video: true,
        slideshow: false,
        controlNav: false
    });
});
$(document).ready(function () {

    $('.slick-slider').slick({
        dots: true,
        customPaging: function (slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a class="pager-item">' + (i + 1) + '</a>';
        },
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    rows: 1,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    rows: 2,
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    rows: 2,
                    slidesToShow: 4,
                }
            },
        ]

    });
    $("#hero-slider").lightSlider({
        gallery: true,
        item: 1,
        loop: true,
        thumbItem: 9,
        slideMargin: 0,
        enableDrag: false,
        currentPagerPosition: 'left',
        onSliderLoad: function (el) {
            el.lightGallery({
                selector: '#imageGallery .lslide'
            });
        }
    });

    $('#light-slider').lightSlider({
        item: 3,
        loop: false,
        slideMove: 2,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed: 600,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    item: 2,
                    slideMove: 1,
                    slideMargin: 6,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    item: 1,
                    slideMove: 1
                }
            }
        ]
    });

    $(".js-worker-read-more").on('click', function () {
        var id = $(this).data('id');
        $('.js-worker-content--' + id).toggleClass('d-none');
        $('.js-worker-read-more--' + id).toggleClass('d-none');
    });
});
$(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('.selectpicker').selectpicker();

    $('.js-map-item-toggler').on("click", function () {
        var className = $(this).data('target');
        $(".js-map-item.active:not(" + className + ")").removeClass('active');
        var item = $(className);
        item.toggleClass('active')
    });
    $(".js-mobile-lang-selector").on('click', function () {
        let selectedLang = $(this).data('lang');
        changeLang(selectedLang);
    });
    $("select.js-lang-select").on('change', function (e) {
        let selectedLang = $(this).val();
        changeLang(selectedLang);
    });

    $(".js-subnav-toggler").hover(
        function () {
            let subnav = $($(this).data('target'));
            subnav.show();
        },
        function () {
            let subnav = $($(this).data('target'));
            subnav.hide();
        },
    );
    $(".subnav").hover(
        function () {
            $(this).show();
        },
        function () {
            $(this).hide();
        },
    );
});

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });
}



